.hero-header {
  padding: 10px 20px;
  position: relative;
  webkit-backdrop-filter: blur(5px);
  z-index: 100;
}

.calendly-inline-widget {
  height: 550px !important;
  min-width: 100% !important;
}

.coupon-code-form-text-fields.MuiFormControl-root {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.demo-popup-calendly-schedule .MuiDialog-paper {
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
}

.hero-container {
  overflow-x: hidden;
  margin-top: 2%;
}

.document-viewer-main-container {
  display: flex;
  background-color: #e8e8e8;
}

.legitt-custom-pdf-page-image {
  width: 21cm;
  box-shadow: 0px 0px 10px 0px #a2a2a240;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
}

.pdf-pages-section-container {
  padding-top: 20px;
  width: 67%;
}

.right-container-pdf-view {
  width: 33%;
  padding: 10px;
  padding-top: 20px;
  background-color: #fff;
}

.self-icon {
  border-radius: 50%;
  width: 30px;
}

.self-sign-text {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

.self-sign-text .MuiFormControlLabel-root {
  width: 100% !important;
  justify-content: space-between;
  text-align: left;
}

.self-sign-text .MuiTypography-root {
  font-size: 14px !important;
  color: #212529;
}
.workflow-left-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
}
.workflow-right-container {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workflowBackground {
  position: absolute;
  width: 70%;
}
.self-container {
  /* border: 1px solid #d8d8d8; */
  border-radius: 4px;
  border-left: 4px solid #fdb718;
  cursor: pointer;
  background-color: #ffe5aa;
  padding: 5px 20px;
  width: 100%;
  margin: 0px 5px;
  justify-content: space-between;
  display: flex;
  gap: 10px;
  align-items: center;
}

.self-other-container {
  /* border: 1px solid #d8d8d8; */
  border-radius: 4px;
  border-left: 4px solid #07a0c3;
  cursor: pointer;
  background-color: #cff6ff;
  width: 100%;
  padding: 5px 20px;
  margin: 0px 5px;
  justify-content: space-between;
  display: flex;
  gap: 10px;
  align-items: center;
}

.other-container {
  /* border: 1px solid #d8d8d8; */
  border-radius: 4px;
  border-left: 4px solid #c96868;
  cursor: pointer;
  width: 100%;
  background-color: #f3e2e2;
  padding: 5px 20px;
  margin: 0px 5px;
  justify-content: space-between;
  display: flex;
  gap: 10px;
  align-items: center;
}

.information-container {
  border-top: 1px solid #c9c9c9;
  margin-top: 29px;
}

.drawer-esign-parent-container {
  display: none;
}

.loader-text {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  margin-top: 10px;
  color: #000;
  margin-bottom: 10px !important;
}

.key-facts-container {
  font-size: 14px;
  font-weight: 300;
  color: rgb(48, 48, 48);
  text-align: center;
}

.document-link {
  text-decoration: none;
  transition: text-decoration 0.3s ease;
  color: #06526d;
  cursor: pointer;
}

.document-link:hover {
  text-decoration: underline;
}

.loader-main-container-document-uploading {
  position: fixed;
  top: 0%;
  background: #ffffff00;
  width: 100%;
  z-index: 999999999;
  transition: 0.5s ease-in-out;
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.selected-file-name-text {
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  margin-inline: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: start;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
  margin-bottom: 11px;
  color: #000;
}

#pdf-pages-section {
  height: calc(100vh - 79px);
  overflow-y: auto;
}
.selected-file-name-input-field {
  background-color: #fafafa;
  padding: 7px 12px;
  border-radius: 8px;
}
.information-container-title {
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  margin: 10px 10px;
  letter-spacing: 0.5px;
}

.email-container {
  background-color: #ffffff;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-input-affix-wrapper > input.ant-input {
  padding-inline: 7px !important;
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

.helper-text-for-mail-input {
  font-size: 12px;
  color: #707070;
  text-align: left;
  margin-top: 0px;
  padding-left: 20px !important;
}

.Button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.header-main-text2 {
  display: none;
}

.header-main-subText2 {
  display: none;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.email-input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
  color: #333;
  width: 100%;
  outline: none;
}
.faqs-accoridion.MuiPaper-root {
  margin-inline: 3% !important;
}
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
  transition: width 0.3s;
}

.email-input[type="text"]:focus + .underline {
  width: 100%;
}

@keyframes floating-label {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-25px);
    opacity: 0;
  }
}

.email-input[type="text"]:placeholder-shown + label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #777;
  pointer-events: none;
  transition: transform 0.3s, font-size 0.3s, color 0.3s;
}

.email-input[type="text"]:focus:not(:placeholder-shown) + label {
  transform: translateY(-25px);
  font-size: 12px;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #ffa600 0%, #c850c0 46%, #4158d0 100%);
  animation: floating-label 0.3s forwards;
}

.nfttext {
  background: linear-gradient(43deg, #ffa600 0%, #c850c0 46%, #4158d0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  margin-top: 20px;
  padding: 0px 2%;
}

.nftMainText {
  font-size: 100px;
}

.nftSubText {
  font-size: 28px;
}

.dragDropIcon {
  width: 50px;
}

.button--submit {
  min-height: 50px;
  padding: 0.5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #06526d;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.gifContainer {
  width: 100%;
  display: flex;
  z-index: -1;
  margin: 20px 0px;
  justify-content: center;
}

.successText {
  text-align: center;
  margin-top: -30px !important;
  font-size: 15px;
  font-weight: 400;
  margin: 10px 0px;
  color: #70b03c;
}

.successGif {
  width: 100px;
}

.button--submit:hover {
  background-color: #06526d;
}

.joinWaitListContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nftContainer {
  display: flex;
  padding: 0px 6%;
  justify-content: center;
  align-items: center;
}

.waitlist-btn {
  --color: #fd9843;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  border: none;
  background-color: transparent;
}

.waitlist-btn div {
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 14px;
  background: var(--color);
  border-radius: 2rem;
  color: white;
  padding: 1rem;
}

.reset-icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.join-btn {
  font-family: monospace;
  background-color: #f3f7fe;
  color: #07a0c3;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100px;
  display: flex;
  font-family: "Inter", sans-serif;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  transition: 0.3s;
}

.join-btn:hover {
  background-color: #07a0c3;
  box-shadow: 0 0 0 5px #3b83f65f;
  color: #fff;
}

.waitlist-btn::before {
  content: "";
  z-index: -1;
  background-color: var(--color);
  border: 2px solid white;
  border-radius: 2rem;
  width: 110%;
  height: 103%;
  position: absolute;
  transform: rotate(10deg);
  transition: 0.2s;
  opacity: 0.2;
}

.waitlist-btn:hover {
  cursor: pointer;
  filter: brightness(1.2);
  transform: scale(1.1);
}

.waitlist-btn:hover::before {
  transform: rotate(0deg);
  opacity: 1;
}

.waitlist-btn svg {
  transform: translateX(-200%);
  transition: 0.5s;
  width: 0;
  opacity: 0;
}

.waitlist-btn:hover svg {
  width: 25px;
  transform: translateX(0%);
  opacity: 1;
}

.waitlist-btn:active {
  filter: brightness(1.4);
}

.legittAIMainContainer {
  display: flex;
  padding: 15px 4%;

  /* height: 52vh; */
  justify-content: center;
  align-items: center;
}

.staticTextSign.small-screen {
  display: none;
}

.logo-img {
  z-index: 9999999999999;
  position: relative;
  width: 100%;
  height: fit-content;
  max-height: 80px;
  max-width: 99px;
}

.nftImg {
  width: 500px;
  filter: drop-shadow(0px 10px 30px #efefef);
}

.upload-document-header-container {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999999999;
  background-color: #fff;
  webkit-backdrop-filter: blur(5px);

  justify-content: space-between;
  align-items: center;
  padding: 5px 24px;
}

.upload-document-main-container {
  margin-top: 80px;
}

.heroMainTextContainer {
  /* position: relative; */
}

.header-main-text {
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2;
  text-align: left;
}

.upload-document-container {
  padding: 0 2rem;
  z-index: 99;
  justify-content: center;
}

.header-main-subText {
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
}

.aiEnabledText {
  font-size: 1.75rem !important;
  font-weight: 300;
  margin-top: 20px;
}

.upload-document-header {
  margin-top: 50px;
  flex: 5;
  padding: 30px 10px;
}

.uploadArea {
  z-index: 1 !important;
  display: flex;
  justify-content: center;
  margin: 10px 0px;
  z-index: 99999999;
  position: relative;
  align-items: center;
  border: 2px dashed #bbb;
  background-color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  margin-inline: 4%;
  min-height: 200px;
  cursor: pointer;
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compliances-main-container {
  padding: 20px 20px;
}

.slider.compliances-card {
  display: none;
}

.compliances-container-header {
  font-size: 40px;
  margin-bottom: 60px;
  line-height: 1.1666666667;
  text-align: left;

  padding: 0px 4%;
  width: 100%;

  color: #000;
  font-weight: 800;
  margin-top: 60px;
}

.trusted-esign-min-container {
  display: flex;
  min-height: 80vh;
  gap: 20px;
  padding: 0px 4%;

  justify-content: center;
  /* align-items: center; */
}
.trusted-left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: left;
}
.trusted-right-container {
  position: relative;
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}
.trusted-background {
  position: absolute;
  right: -290px !important;
  width: 100%;
  z-index: -1;
}
.trusts-icons-container {
  display: flex;
  gap: 20px;
  margin: 20px;
}

.trusts-icons {
  width: 140px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  gap: 10px;
  padding: 20px;
  border-radius: 20px;
}

.trusts-img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: darken;
}
.compliances-cards-container {
  width: 100%;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.trust-text-container {
  font-size: 40px;
  font-weight: 800;
}
.trust-subtext-container {
  font-size: 20px;
}
.compliances-cards {
  padding: 10px 40px;
  border-radius: 12px;
  text-align: center;
  z-index: 1000;
  border-radius: 4px;
  align-items: center;
}
.signImg {
  position: absolute;
  /* top: -50%; */
  mix-blend-mode: darken;
  width: 300px;
  opacity: 0.9;
  /* right: 0; */
}

.compliances-cards.slide {
  padding: 50px 40px;
  border-radius: 12px;
  text-align: center;
  z-index: 1000;
  border-radius: 4px;
  align-items: center;
}

.compliances-img {
  width: 95px;
  height: auto;
}

.animationTextUpload {
  font-size: 100px;
  font-weight: 800;
  color: #007dfe;
  position: absolute;
  /* z-index: -1 !important;
  top: -30px; */
  right: 39%;
  text-align: right;
}

.animationTextCreate {
  font-size: 100px;
  color: #f29900;
  position: absolute;
  z-index: -1 !important;
  right: 42%;
  text-align: right;
  top: -30px;
  font-weight: 800;
}

.staticTextSign {
  font-size: 100px;
  display: flex;
  font-weight: 800;
  position: relative;
  text-align: left;
  margin-top: 150px;
}

.staticCircleImage {
  width: 200px;
  height: 200px;
  position: absolute;
  opacity: 0.06;
  left: 55%;
  background-color: #007dfe;
  transform: translateX(17%);
  top: -20px;
  z-index: -1 !important;
  transition: 0.5s ease-in-out;
  border-radius: 50%;
}

.guideContainer {
  min-height: 50vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
}

.guideHeaderText {
  font-size: 40px;
  margin-bottom: 60px;
  line-height: 1.1666666667;
  text-align: left;
  padding: 0px 4%;
  color: #000;
  font-weight: 800;
}

.uploadImg {
  width: 144px;
  position: absolute;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transform: translateX(21%);
  top: 28%;
  border-radius: 20px;
  left: 100%;
  z-index: 100 !important;
}

.createImg {
  width: 144px;
  position: absolute;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transform: translateX(60%);
  top: 38%;
  border-radius: 20px;
  left: 89%;
  z-index: 100 !important;
}

.compliances-text {
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
}

.starIcon {
  width: 30px;
}

.rightContainerLegittAI {
  flex: 5;
}

.guideMainContainer {
  margin-top: 80px;
  margin-inline: 4%;
  /* border: 1px solid #D9D9D9; */
  justify-content: center;
  padding: 40px 40px;
  display: flex;
  background-color: #f4f4f4;
}

.leftGuideContainer {
  width: 50%;
}

.rightGuideContainer {
  width: 30%;
}

.guideHeader {
  font-size: 25px;
  font-weight: 800;
  text-align: left;
  padding-left: 40px;
  color: #000;
  margin-bottom: 20px;
}

.guideImage {
  width: 40px;
}

.guideText {
  margin-top: 20px;
  /* font-size: 16px; */
  font-weight: 400;
}

.alertImage {
  z-index: 99;
  width: 670px;
}
.alertImage.security {
  z-index: 99;
  width: 500px;
}

.arrowIcon {
  width: 30px;
  margin-left: 10px;
  margin-top: 5px;
  transition: 0.2s;
}

.arrowIconbottom {
  width: 30px;
  margin-left: 10px;
  margin-top: 5px;
  transition: 0.2s;
}

.guideIconContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 60px;
  gap: 40px;
  text-align: center;
}

.alertContainer {
  display: flex;
  min-height: 80vh;
  justify-content: space-between;
  align-items: center;
  gap: 110px;
  margin-right: 20px !important;
  margin-inline: 4%;
}

.alertHeader {
  font-size: 40px;
  font-weight: 800;
  text-align: left;
  color: #000;
  margin-bottom: 20px;
}

.alertSubHeader {
  font-size: 20px;

  font-weight: 400;
  text-align: left;
  color: #151515;
  margin-bottom: 20px;
}

.clientFeadbackHeader {
  font-size: 40px;
  font-weight: 800;
  text-align: left;
  color: #000;
  margin-top: 20px;
  padding: 0px 4%;
}

.uploadContainerHeadingText {
  z-index: 100;
  /* margin-top: 20px; */
  padding: 0 4%;
  color: #000;
  text-align: left;
  font-size: 20px !important;
}

.supportText {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-inline: 20px;
  color: #707070;
}

.clientCardsContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
  padding-inline: 20%;
  flex-wrap: wrap;
}

.partnerCard {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnerCardImg {
  width: 120px;
}

.clientCard {
  padding: 20px 40px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  width: 250px;
  min-height: 340px;
  transition: 0.2s;
}

.clientCard:hover {
  transform: scale(1.1);
}

.clientCardImage {
  display: flex;
  justify-content: flex-start;
}

.clientProfile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.clientText {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.ClientSubtext {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
  color: #707070;
}

.feedbackText {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
  margin-bottom: 20px;
}

.MuiTypography-root {
  font-family: "Inter", sans-serif !important;
}

.templateText {
  position: relative;
  z-index: 100;
  margin-top: 10px;
  padding: 0 8%;
  text-align: left;
  font-size: 20px !important;
}

.faQContainer {
  margin: 30px 0px;
  position: relative;
  /* background-color: #f4f4f4; */
}

.progressContainer {
  width: 500px;
}

.faQHeader {
  font-size: 40px;
  font-weight: 800;
  text-align: left;
  padding: 0px 4%;
  color: #000;
  margin-bottom: 20px;
}

.footerContainer {
  z-index: 1;
  position: relative;
}

.uploadDialogContainer {
  z-index: 999999999 !important;
}
.uploadDialogContainer .MuiDialog-paper {
  max-width: 700px;
  border-radius: 20px !important;
  width: 100%;
}

.dialogContent {
  width: 100%;
}

.emailAddressHeader {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.viewDocumentText {
  font-size: 20px;
  margin-top: 70px;
  margin-bottom: -20px !important;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 0px;
  text-decoration: none;
}

.firstNameLastNameInput {
  display: flex;
  gap: 10px;
}

.dragAndDropText {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.emailInput {
  width: 100%;
  margin-bottom: 10px !important;
}

.submitButtonPopup {
  gap: 10px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

@media screen and (max-width: 1504px) {
  .nftMainText {
    font-size: 70px;
  }
}

@media screen and (max-width: 1367px) {
  .legittAIMainContainer {
    padding-right: 50px;
  }

  .animationTextCreate {
    right: 37%;
  }

  .animationTextUpload {
    right: 33%;
  }

  .staticCircleImage {
    left: 60%;
  }
}

@media screen and (max-width: 1332px) {
  .upload-document-header {
    flex: 3;
  }

  .rightContainerLegittAI {
    flex: 4;
  }
}

@media screen and (max-width: 1245px) {
  .nftMainText {
    font-size: 50px;
  }
}

@media screen and (max-width: 1235px) {
  .legittAIMainContainer {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 1200px) {
  .progressContainer {
    width: 200px;
  }

  .document-viewer-main-container {
    gap: 10px;
  }
  .slider.compliances-card {
    display: block;
  }
  .trusted-esign-min-container {
    display: none;
  }
}

@media screen and (max-width: 1170px) {
  .clientCard {
    width: 80%;
  }

  .alertHeader {
    font-size: 30px;
  }

  .clientFeadbackHeader {
    font-size: 30px;
  }

  .faQHeader {
    font-size: 30px;
  }

  .guideHeaderText {
    font-size: 30px;
  }

  .compliances-container-header {
    font-size: 30px;
  }

  .compliances-container-header {
    padding: 0px 20px;
  }

  .guideHeaderText {
    padding: 0px 20px;
  }

  .alertContainer {
    gap: 10px;
  }
}

@media screen and (max-width: 1125px) {
  .alertImage {
    width: 93%;
  }

  .alertContainer {
    margin-inline: 20px;
  }

  .compliances-cards-container {
    display: none;
  }

  .compliances-cards {
    margin-bottom: 20px;
  }

  .rightContainerLegittAI {
    display: block;
  }

  .legittAIMainContainer {
    display: block;
  }

  .upload-document-header {
    width: 100%;
  }

  .rightContainerLegittAI {
    width: 100%;
  }

  .staticTextSign {
    font-size: 70px;
    margin-top: 95px;
  }

  .animationTextUpload {
    font-size: 70px;
    /* top: -10px; */
    right: auto;
    margin-right: 20px;
  }

  .animationTextCreate {
    font-size: 70px;

    margin-right: 20px;
    top: -10px;
    right: auto;
  }

  .upload-document-container {
    width: 100%;
    position: relative;
    margin-left: 10%;
  }

  .staticTextSign {
    display: none;
  }

  .staticTextSign.small-screen {
    display: block;
    /* margin-top: 217px; */
    font-size: 70px;
    text-align: center;
    margin-right: 0px;
  }

  .uploadImg {
    left: 100%;
    transform: translateX(75%);
  }

  .createImg {
    left: 100%;
    transform: translateX(80%);
  }

  .animationTextCreate {
    font-size: 70px;
    right: 60%;
  }

  .animationTextUpload {
    font-size: 70px;
    right: 49%;
  }

  .staticCircleImage {
    width: 200px;
    height: 200px;
    left: 44%;
  }

  /* 
  .legittAIMainContainer {

    height: 100vh;

  } */

  .header-main-text {
    display: flex;
    justify-content: center;
  }

  .header-main-subText {
    display: flex;
    gap: 7px;
    justify-content: center;
  }

  .aiEnabledText {
    margin-inline: 15px;
  }
}

@media screen and (max-width: 1052px) {
  /* .faqs-accoridion.MuiPaper-root {
    margin-inline: 0% !important;
  } */
}

@media screen and (max-width: 1001px) {
  .right-container-pdf-view {
    display: none;
  }

  .drawer-esign-parent-container {
    display: block;
  }

  .drawer-esign-container {
    z-index: 9999999999;
  }

  .esign-dawer-container {
    z-index: 9999999999;
  }

  .floatingButton {
    position: absolute;
    background-color: #007dfe;
    bottom: 20px !important;
    right: 20px;
  }

  .ant-drawer {
    position: fixed;
    inset: 0;
    z-index: 99999999999;
    pointer-events: none;
  }

  .pdf-pages-section-container {
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 940px) {
  .upload-document-container {
    margin-right: 0px;
  }

  .nftMainText {
    font-size: 80px;
    text-align: center;
  }

  .nfttext {
    font-size: 30px;
    text-align: left;
    padding-inline: 0px;
    margin-top: 0px;
  }

  .nftContainer {
    display: block;
    margin-bottom: 40px;
  }
}

.arrowIconbottom {
  display: none;
}

@media screen and (max-width: 927px) {
  .guideIconContainer {
    display: block;
  }

  .arrowIconbottom {
    display: block;
    rotate: 90deg;
  }

  .arrowIcon {
    display: none;
  }
}

@media screen and (max-width: 480px), screen and (max-height: 400px) {
  /* .legittAIMainContainer {
    height: 85vh;
  } */
}

@media screen and (max-width: 890px) {
  .email-container {
    display: block !important;
  }

  .firstNameLastNameInput {
    display: block !important;
  }
}

@media screen and (max-width: 863px) {
  .guideMainContainer {
    justify-content: left;
  }

  .leftGuideContainer {
    width: 100%;
  }

  .rightGuideContainer {
    width: 0%;
  }

  .legittAIMainContainer {
    padding: 10px 20px;
  }
  .compliances-container-header {
    margin-top: 20px !important;
    margin-bottom: 4 0px !important;
  }
  .upload-document-header {
    padding: 5px 10px;
  }
  .slider.compliances-card {
    margin-bottom: 0;
    /* min-height: auto  ; */
  }
}

@media screen and (max-width: 802px) {
  .progressContainer {
    width: 100%;
  }

  .alertHeader {
    font-size: 25px;
  }

  .guideHeader {
    font-size: 25px;
  }

  .clientCardsContainer {
    padding-inline: 0%;
  }
}

@media screen and (max-width: 727px) {
  .header-main-text {
    display: block;
  }

  .aiEnabledText {
    margin-inline: 0px;
  }

  .staticTextSign.small-screen {
    font-size: 50px;
    /* margin-top: 188px; */
    /* left: 30%; */
  }

  .nftMainText {
    font-size: 50px;
  }

  .animationTextCreate {
    font-size: 50px;
    top: 26px;
    right: 60%;
  }

  .uploadImg {
    transform: translateX(56%);
    top: -7%;
  }

  .createImg {
    transform: translateX(57%);
    top: 10%;
  }

  .animationTextUpload {
    font-size: 50px;
    right: 51%;
    top: 26px;
  }

  .staticCircleImage {
    left: 40%;
  }

  .upload-document-header {
    margin: 10px 0px;
  }
}

@media screen and (max-width: 680px) {
  .dialogContent {
    width: 100%;
    overflow-x: hidden;
  }

  .compliances-container-header {
    font-size: 25px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .guideText {
    font-size: 20px;
  }

  .guideHeaderText {
    font-size: 25px;
    margin-bottom: 60px;
  }

  .guideContainer {
    margin-top: 0px;
  }
}

@media screen and (max-width: 645px) {
  .alertContainer {
    gap: 5px;
    margin-inline: 20px;
    padding: 20px 0px;
    flex-direction: column;
  }
  .workflow-left-container {
    width: 100%;
  }
  .workflow-right-container {
    width: 100%;
    margin-top: 100px;
  }
  .workflowBackground {
    width: 300px;
  }
  .alertImage {
    width: 100%;
  }
  .alertImage.security {
    width: 300px;
  }

  .alertContainer:nth-child(1) {
    flex-direction: column-reverse;
  }

  .upload-document-header {
    padding: 10px;
  }
}

@media screen and (max-width: 539px) {
  .animationTextCreate {
    font-size: 40px;
    right: 37%;
    margin-right: 0px;
  }

  .animationTextUpload {
    font-size: 40px;
    right: 50%;
    margin-right: 0px;
  }

  .staticTextSign.small-screen {
    font-size: 40px;
    /* margin-top: 120px; */
  }

  .staticCircleImage {
    display: none;
  }

  .createImg {
    display: none;
  }

  .uploadImg {
    display: none;
  }
}

@media screen and (max-width: 1200px) and (max-height: 610px) {
  /* .legittAIMainContainer {
    height: 100vh;
  } */
  .compliances-main-container {
    padding: 0px 20px;
  }
  .slider.compliances-card {
    margin-bottom: 0;
  }

  .staticTextSign.small-screen {
    /* margin-top: 160px; */
  }
}

@media screen and (max-width: 600px) and (max-height: 900px) {
  .upload-document-header {
    font-size: 12px;
  }

  /* .legittAIMainContainer {
    height: 85vh;
  } */

  .uploadArea {
    font-size: 12px !important;
  }

  .dragAndDropText {
    font-size: 12px;
  }

  .compliances-container-header {
    font-size: 20px;
  }

  .compliances-img {
    /* width: 120px !important; */
  }

  .guideHeader {
    padding-left: 0px;
    font-size: 20px;
  }

  .guideMainContainer {
    padding: 5px 10px;
    margin-inline: 10px;
  }
}

@media screen and (max-width: 500px) {
  .partnerCard {
    box-shadow: none;
    width: 100px;
    height: 100px;
  }

  .nftContainer {
    padding: 0px;
  }

  .upload-document-header {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .header-main-text2 {
    display: block;
    font-size: calc(1.3rem + 0.6vw);
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
  }

  .uploadContainerHeadingText {
    font-size: calc(1.3rem + 0.6vw);
  }

  .header-main-subText2 {
    display: block;

    font-size: 14px;
    text-align: left;
  }

  .header-main-subText {
    display: none;
  }

  /* .header-main-text {
    display: none;
  } */

  .guideHeaderText {
    font-size: calc(1.3rem + 0.6vw);
  }

  .clientFeadbackHeader {
    font-size: calc(1.3rem + 0.6vw);
  }

  .compliances-container-header {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: calc(1.3rem + 0.6vw);
  }

  .clientCardsContainer {
    margin-top: 0px;
  }

  .faQHeader {
    font-size: calc(1.3rem + 0.6vw);
    margin-bottom: 10px;
  }

  .faQContainer {
    margin-top: 0px;
    padding: 0px 20px;
  }

  .animationTextUpload {
    right: 50%;
  }

  .animationTextCreate {
    right: 30%;
  }

  .slider {
    height: 130px;
  }

  .dragAndDropText {
    font-size: 14px;
  }

  .templateText {
    font-size: 14px;
  }
}

@media screen and (max-width: 435px) {
  .nftImg {
    width: 334px;
  }
}

@media screen and (max-width: 390px) {
  .legittAIMainContainer {
    padding: 5px 10px;
    /* height: 70vh; */
  }

  .upload-document-header {
    padding: 5px 10px;
    padding-bottom: 10px;
  }

  .guideIconContainer {
    padding-inline: 10px;
  }

  .faQContainer {
    padding: 0px;
  }

  .email-container {
    padding: 0px;
  }

  .animationTextCreate {
    right: 38%;
    font-size: 40px;
  }

  .animationTextUpload {
    right: 38%;
    font-size: 40px;
  }

  .staticTextSign.small-screen {
    /* right: 30%; */
    font-size: 40px;
  }

  .header-main-text {
    font-size: 25px;
  }

  .header-main-subText {
    font-size: 20px;
  }
}

@media screen and (max-width: 350px) {
  .nftMainText {
    font-size: 35px;
  }

  .nftSubText {
    font-size: 20px;
  }
}

@media screen and (max-width: 300px) {
  .nftImg {
    width: 234px;
  }
}

.email-popup-main-upgrade-container {
  display: flex;
  padding: 20px 40px;
  /* min-height: 40vh; */
  justify-content: center;
}
.email-popup-upgrade-section {
  text-align: center;
}
.email-popup-upgrade-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.email-popup-upgrade-points-container {
  margin-bottom: 20px;
}
.email-popup-upgrade-points-container {
  margin-bottom: 20px;
}
.email-popup-upgrade-point {
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.email-popup-upgrade-btn-container {
  text-align: center;
}
.document-created-text {
  font-size: 20px;
  font-weight: 700;
  margin-top: -40px;
}
.document-create-helper-text {
  font-size: 14px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 10px;
  margin-top: 5px;
}
.email-popup-upgrade-btn-container {
  display: flex;
  text-align: center;
  justify-content: center;
}

.continue-application {
  --color: #fff;
  --background: #115dfc;
  --background-left: #0453fc;
  --folder: #f3e9cb;
  --folder-inner: #beb393;
  --paper: #ffffff;
  --paper-lines: #bbc1e1;
  --paper-behind: #e1e6f9;
  --pencil-cap: #fff;
  --pencil-top: #275efe;
  --pencil-middle: #fff;
  --pencil-bottom: #5c86ff;
  --shadow: rgba(13, 15, 25, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 69px;
  transition: background 0.3s;
  color: var(--color);
  background: var(--bg, var(--background));
}

.continue-application > div {
  top: 0;
  left: 0;
  bottom: 0;
  width: 53px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  background: var(--background-left);
}

.continue-application > div .folder {
  width: 23px;
  height: 27px;
  position: absolute;
  left: 15px;
  top: 13px;
}

.continue-application > div .folder .top {
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application > div .folder .top svg {
  width: 24px;
  height: 27px;
  display: block;
  fill: var(--folder);
  transform-origin: 0 50%;
  transition: transform 0.3s ease var(--fds, 0.45s);
  transform: perspective(120px) rotateY(var(--fr, 0deg));
}

.continue-application > div .folder:before,
.continue-application > div .folder:after,
.continue-application > div .folder .paper {
  content: "";
  position: absolute;
  left: var(--l, 0);
  top: var(--t, 0);
  width: var(--w, 100%);
  height: var(--h, 100%);
  border-radius: 1px;
  background: var(--b, var(--folder-inner));
}

.continue-application > div .folder:before {
  box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application > div .folder:after,
.continue-application > div .folder .paper {
  --l: 1px;
  --t: 1px;
  --w: 21px;
  --h: 25px;
  --b: var(--paper-behind);
}

.continue-application > div .folder:after {
  transform: translate(var(--pbx, 0), var(--pby, 0));
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application > div .folder .paper {
  z-index: 1;
  --b: var(--paper);
}

.continue-application > div .folder .paper:before,
.continue-application > div .folder .paper:after {
  content: "";
  width: var(--wp, 14px);
  height: 2px;
  border-radius: 1px;
  transform: scaleY(0.5);
  left: 3px;
  top: var(--tp, 3px);
  position: absolute;
  background: var(--paper-lines);
  box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
}

.continue-application > div .folder .paper:after {
  --tp: 6px;
  --wp: 10px;
}

.continue-application > div .pencil {
  height: 2px;
  width: 3px;
  border-radius: 1px 1px 0 0;
  top: 8px;
  left: 105%;
  position: absolute;
  z-index: 3;
  transform-origin: 50% 19px;
  background: var(--pencil-cap);
  transform: translateX(var(--pex, 0)) rotate(35deg);
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application > div .pencil:before,
.continue-application > div .pencil:after {
  content: "";
  position: absolute;
  display: block;
  background: var(
    --b,
    linear-gradient(
      var(--pencil-top) 55%,
      var(--pencil-middle) 55.1%,
      var(--pencil-middle) 60%,
      var(--pencil-bottom) 60.1%
    )
  );
  width: var(--w, 5px);
  height: var(--h, 20px);
  border-radius: var(--br, 2px 2px 0 0);
  top: var(--t, 2px);
  left: var(--l, -1px);
}

.continue-application > div .pencil:before {
  -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
}

.continue-application > div .pencil:after {
  --b: none;
  --w: 3px;
  --h: 6px;
  --br: 0 2px 1px 0;
  --t: 3px;
  --l: 3px;
  border-top: 1px solid var(--pencil-top);
  border-right: 1px solid var(--pencil-top);
}

.continue-application:before,
.continue-application:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  background: var(--color);
  transform-origin: 9px 1px;
  transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
  top: 26px;
  right: 16px;
  transition: transform 0.3s;
}

.continue-application:after {
  --r: 45deg;
}

.continue-application:hover {
  --cx: 2px;
  --bg: var(--background-hover);
  --fx: -40px;
  --fr: -60deg;
  --fd: 0.15s;
  --fds: 0s;
  --pbx: 3px;
  --pby: -3px;
  --pbd: 0.15s;
  --pex: -24px;
}
.MuiDialog-container {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

@media screen and (max-width: 501px) {
  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(750px * 6);
    gap: 0px;
  }
  .slider.compliances-card {
    height: 240px !important;
  }
}

.top-nav-main-container .MuiPaper-root.MuiPaper-elevation {
  box-shadow: none !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top: 1px solid #eee !important;
}

.top-nav-main-container .MuiBackdrop-root.MuiModal-backdrop {
  top: 64px !important;
}
.top-nav-main-container.MuiModal-root.MuiDrawer-root {
  top: 64px !important;
}
